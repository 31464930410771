<template>
    <div>
        <div style="margin-bottom: 5px;">
            <el-input v-model="name" placeholder="客户名称" suffix-icon="el-icon-search" style="width: 200px;"
                @keyup.enter.native="loadPost"></el-input>
            <!-- <el-select v-model="storage" placeholder="请选择仓库" style="margin-left: 5px;">
                <el-option
                        v-for="item in storageData"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                </el-option>
            </el-select> -->
            <!-- <el-select v-model="goodstype" placeholder="请选择分类" style="margin-left: 5px;">
                <el-option
                        v-for="item in goodstypeData"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                </el-option>
            </el-select> -->

            <el-button type="primary" style="margin-left: 5px;" @click="loadPost">查询</el-button>
            <el-button type="success" @click="resetParam">重置</el-button>

            <el-button type="primary" style="margin-left: 5px;" @click="add" v-if="user.roleId != 2">新增销售订单</el-button>
            <!-- <el-button type="primary" style="margin-left: 5px;" @click="doInGoods" v-if="user.roleId != 2">科力通采购表格导入</el-button> -->
            <!-- <el-button type="primary" style="margin-left: 5px;" @click="inGoods" v-if="user.roleId!=2">入库</el-button> -->
            <!-- <el-button type="primary" style="margin-left: 5px;" @click="outGoods" v-if="user.roleId!=2">出库</el-button> -->
        </div>
        <el-table :data="tableData" :header-cell-style="{ background: '#f2f5fc', color: '#555555' }" border
            highlight-current-row @current-change="selectCurrentChange">
            <el-table-column prop="id" label="ID" width="60">
            </el-table-column>
            <el-table-column prop="idNo" label="订单号" width="160">
            </el-table-column>
            <!-- <el-table-column prop="customerId" label="客户ID" width="120">
            </el-table-column> -->
            <el-table-column prop="customerName" label="客户名称" width="160">
            </el-table-column>
            <el-table-column prop="skuQuantity" label="SKU种类总数" width="100">
            </el-table-column>
            <el-table-column prop="totalQuantity" label="出售物品总数" width="100">
            </el-table-column>
            <el-table-column prop="totalPaymentAmount" label="总计价格" width="100">
            </el-table-column>
            <el-table-column prop="receivablesAmount" label="应收款" width="60">
            </el-table-column>
            <el-table-column prop="receivedAmount" label="已收款" width="60">
            </el-table-column>
            <el-table-column prop="totalSalesProfit" label="利润" width="60">
            </el-table-column>
            <el-table-column prop="createTime" label="创建时间" width="140">
            </el-table-column>
            <!-- <el-table-column prop="lastUpdateTime" label="更新时间" width="140">
            </el-table-column> -->
            <el-table-column prop="operate" label="操作" v-if="user.roleId != 2">
                <template slot-scope="scope">
                    <el-button size="small" type="success" @click="showProcurementDetails(scope.row)">订单详情</el-button>
                    <el-button size="small" type="success" @click="mod(scope.row)">编辑</el-button>
                    <!-- <el-popconfirm title="确定删除吗？" @confirm="del(scope.row.id)" style="margin-left: 5px;">
                        <el-button slot="reference" size="small" type="danger">删除</el-button>
                    </el-popconfirm> -->
                </template>
            </el-table-column>
        </el-table>
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageNum"
            :page-sizes="[5, 10, 20, 30]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper"
            :total="total">
        </el-pagination>


        <el-dialog title="订单详情" :visible.sync="procurementDialogVisible" width="58%" center>

            <el-table :data="procurementData" :header-cell-style="{ background: '#f2f5fc', color: '#555555' }" border
                highlight-current-row @current-change="selectDetailsCurrentChange">
                <el-table-column prop="idNo" label="订单号" width="100">
                </el-table-column>
                <!-- <el-table-column prop="skuId" label="SkuId" width="120">
                </el-table-column> -->
                <!-- <el-table-column prop="storageId" label="仓库ID" width="160">
                </el-table-column> -->
                <el-table-column prop="name" label="Sku名称" width="200" >
                </el-table-column>
                <el-table-column prop="quantity" label="销售数量" width="100">
                </el-table-column>
                <el-table-column prop="currentSellingPrice" label="单价">
                </el-table-column>
                <el-table-column prop="subTotalAmount" label="总价">
                </el-table-column>
                <el-table-column prop="subTotalProfit" label="总利润">
                </el-table-column>
                <!-- <el-table-column prop="lastUpdateTime" label="更新时间">
                </el-table-column> -->
                <el-table-column prop="createTime" label="创建时间">
                </el-table-column>
            </el-table>
            <el-pagination @size-change="handleDetailsSizeChange" @current-change="handleDetailsCurrentChange" :current-page="pageDetailsNum"
                :page-sizes="[5, 10, 20, 30]" :page-size="pageDetailsSize" layout="total, sizes, prev, pager, next, jumper"
                :total="detailsTotal">
            </el-pagination>
            <span slot="footer" class="dialog-footer">
                <el-button @click="procurementDialogVisible = false;pageDetailsSize = 10;pageDetailsNum = 1">取 消</el-button>
                <el-button type="primary" @click="procurementDialogVisible = false;pageDetailsSize = 10;pageDetailsNum = 1">确 定</el-button>
            </span>
        </el-dialog>


        <el-dialog title="新增销售订单" :visible.sync="newProcurementDialogVisible" width="55%" center>
            <!-- <el-select v-model="supplier" placeholder="请选择供应商" style="margin-left: 5px;" @change="changeSupplierSelect">
                <el-option v-for="item in supplierData" :key="item.id" :label="item.name" :value="item">
                </el-option>
            </el-select> -->
            <div style="margin-bottom: 5px;">
                <el-select v-model="supplier" placeholder="请选择供应商" style="margin-left: 5px;" value-key="id" @change="changeSupplierSelect">
                    <el-option v-for="item in supplierData" :key="item.id" :label="item.name" :value="item">
                    </el-option>
                </el-select>
                <el-button type="primary" style="margin-left: 5px;" @click="addNewRowForPurchase">新增货品</el-button>
                <el-select v-model="customer" placeholder="请选择客户" style="margin-left: 5px;" value-key="id" @change="changeCustomerSelect">
                    <el-option v-for="item in customerData" :key="item.id" :label="item.name" :value="item">
                    </el-option>
                </el-select>
            </div>
            <el-table :data="curNewProcurementData" width="100%" :header-cell-style="{ background: '#f2f5fc', color: '#555555' }" border
                highlight-current-row @current-change="selectCurrentPurchaseSkuChange" @selection-change="handleSelectionChange" @change="procurementChange">
                <el-table-column prop="skuId" label="SkuId" width="60" value-key="id">
                </el-table-column>
                <el-table-column prop="name" label="Sku名称" width="180">
                    <template slot-scope="scope">
                        <el-select filterable clearable @change="changeFn(scope.row,scope.$index)" v-model="scope.row.subjectItem"
                            placeholder="请选择">
                            <!-- <el-option @click="changeOption" v-for="item in skuListData" :key="item.value" :label="item.label"
                                :value="item.value" v-show="item.flag">
                            </el-option> -->
                            <el-option @click="changeSkuOption" v-for="item in skuListData" :key="item.skuId" :label="item.name"
                                :value="item.skuId" v-show="true">
                            </el-option>
                        </el-select>
                    </template>
                </el-table-column>
                <el-table-column prop="currentPurchasePrice" label="采购价" width="100">
                </el-table-column>
                <el-table-column prop="currentSellingPrice" label="售价" width="100">
                </el-table-column>
                <el-table-column prop="name" label="出货仓库" width="180">
                    <template slot-scope="scope">
                        <el-select filterable clearable @change="changeSkuStorage(scope.row,scope.$index)" v-model="scope.row.currentSkuStorageItem"
                            placeholder="请选择">
                            <!-- <el-option @click="changeOption" v-for="item in skuListData" :key="item.value" :label="item.label"
                                :value="item.value" v-show="item.flag">
                            </el-option> -->
                            <el-option v-for="item in skuStorageListData" :key="item.storageId" :label="item.storageName"
                                :value="item.storageId" v-show="true">
                            </el-option>
                        </el-select>
                    </template>
                </el-table-column>
                <el-table-column prop="availableQuantity" label="可售数量" width="100">
                </el-table-column>
                <el-table-column prop="quantity" label="销售数量" width="120">
                    <template slot-scope="scope">
                        <el-input @input="quantityChange" v-model.trim="scope.row.inputQuantity" @focus="onQuantityInputFocus(scope.row,scope.$index)" autocomplete="off" size="small" placeholder="请输入数量"></el-input>
                    </template>
                </el-table-column>
                <el-table-column prop="subTotalAmount" label="总价" width="60">
                </el-table-column>
            </el-table>
            <!-- <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageNum"
                :page-sizes="[5, 10, 20, 30]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper"
                :total="total">
            </el-pagination> -->
            <span slot="footer" class="dialog-footer">
                <el-button @click="newProcurementDialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="postNewSalesOrder">确 定</el-button>
            </span>
        </el-dialog>
        <el-dialog title="订单导入" :visible.sync="orderImportVisible" width="60%" center>
            <el-row>
                <el-col :span="7">
                <el-upload
                    action="/"
                    :on-change="onChange"
                    :auto-upload="false"
                    :show-file-list="false"
                    accept=".xls, .xlsx"
                >
                    <el-button size="small" type="primary">选择文件</el-button>
                </el-upload>
                </el-col>
                <el-col :span="7">
                <!-- <el-button size="small" type="primary" @click="exportExcel">文件导出</el-button> -->
                <!-- <el-button size="small" type="primary">文件导出</el-button> -->
                </el-col>
            </el-row>
            <el-table
                style="margin:20px 0;"
                :data="outputs"
                border
                :header-cell-style="{background:'#f1f1f1',color:'#606266'}"
            >
                <el-table-column label="料号" prop="partNo"></el-table-column>
                <el-table-column label="产品名称" prop="name" width="200"></el-table-column>
                <el-table-column label="数量" prop="quantity"></el-table-column>
                <el-table-column label="单价/元" prop="unitPrice"></el-table-column>
                <el-table-column label="单位" prop="skuUnit"></el-table-column>
                <!-- <el-table-column label="装箱数" prop="qty"></el-table-column> -->
                <el-table-column label="小计" prop="subTotal"></el-table-column>
            </el-table>
            <span slot="footer" class="dialog-footer">
                <el-button @click="importOrderCancel">取 消</el-button>
                <el-button type="primary" @click="importOrder">导 入</el-button>
            </span>
        </el-dialog>
    </div>
</template>


<script>
    import XLSX from "xlsx";
export default {
    name: "SalesSummaryManage",
    data() {
        let checkCount = (rule, value, callback) => {
            if (value > 9999) {
                callback(new Error('数量输入过大'));
            } else {
                callback();
            }
        };

        let checkPrice = (rule, value, callback) => {
            if (value > 9999) {
                callback(new Error('数量输入过大'));
            } else {
                callback();
            }
        };

        return {
            user: JSON.parse(sessionStorage.getItem('CurUser')),
            storageData: [],
            supplierData: [],
            customerData: [],
            goodstypeData: [],
            tableData: [],
            procurementData: [],
            skuListData:[],
            skuStorageListData:[],
            curNewProcurementData: [{
                skuId: '',
                name: '',
                currentPurchasePrice: 0,
                currentSellingPrice: 0,
                quantity: 0,
                subTotalAmount: 0,
            }],
            curNewProcurementRow:'',
            pageSize: 10,
            pageNum: 1,
            pageDetailsSize: 10,
            pageDetailsNum:1,
            total: 0,
            detailsTotal: 0,
            name: '',
            storage: '',
            supplier: '',
            customer: '',
            goodstype: '',
            centerDialogVisible: false,
            procurementDialogVisible: false,
            newProcurementDialogVisible: false,
            orderImportVisible: false,
            innerVisible: false,
            currentRow: {},
            currentDetailsRow: {},
            tempUser: {},
            loadProcurementIdNo: 0,
            availableQuantity:0,
            form: {
                id: '',
                idNo: 0,
                supplierId: '',
                supplierName: '',
                skuQuantity: 0,
                totalQuantity: 0,
                totalPaymentAmount: 0,
                payableAccounts: 0,
                paidAccounts: 0,
                createTime: '',
                lastUpdateTime: ''
            },
            form1: {
                goods: '',
                goodsname: '',
                count: '',
                username: '',
                userid: '',
                adminId: '',
                remark: '',
                action: '1'
            },
            rules1: {

            },
            rules: {
                partNo: [
                    { required: true, message: '请输入料号名', trigger: 'blur' }
                ],
                name: [
                    { required: true, message: '请输入物品名', trigger: 'blur' }
                ],
                storage: [
                    { required: true, message: '请选择仓库', trigger: 'blur' }
                ],
                goodstype: [
                    { required: true, message: '请选择分类', trigger: 'blur' }
                ],
                count: [
                    { required: true, message: '请输入数量', trigger: 'blur' },
                    { pattern: /^([1-9][0-9]*){1,4}$/, message: '数量必须为正整数字', trigger: "blur" },
                    { validator: checkCount, trigger: 'blur' }
                ],
                price: [
                    { required: true, message: '请输入价格', trigger: 'blur' },
                    { pattern: /^([1-9][0-9]*){1,4}\.([0-9][0-9]*){0,2}$/, message: '数量必须两位小数', trigger: "blur" },
                    { validator: checkPrice, trigger: 'blur' }
                ],
                qty: [
                    { required: true, message: '请输入数量', trigger: 'blur' },
                    { pattern: /^([1-9][0-9]*){1,4}$/, message: '数量必须为正整数字', trigger: "blur" },
                    { validator: checkCount, trigger: 'blur' }
                ],
            },
            outputs: [], // 保存读取出来的数据列表
            fileData: "" // 保存选择的文件数据
        }
    },
    methods: {
        handleSelectionChange(val){
            console.log("***********  handleSelectionChange  *********************")
            JSON.stringify(val)
        },
        doSelectUser(val) {
            console.log(val)
            this.tempUser = val
        },
        confirmUser() {
            this.form1.username = this.tempUser.name
            this.form1.userid = this.tempUser.id

            this.innerVisible = false
        },
        selectCurrentPurchaseSkuChange(val){
            console.log("=======  selectCurrentPurchaseSkuChange  ===============")
            console.log(val)
        },
        selectCurrentChange(val) {
            this.currentRow = val;
        },
        selectDetailsCurrentChange(val){
            this.currentDetailsRow = val;
        },
        changeSupplierSelect(val) {
            console.log("=============  changeSupplierSelect  ========================")
            console.log(val)
            // console.log(this.supplier)
            console.log("**************************************************")
            // this.loadSkuListBySupplierId(this.supplier.id)
            this.loadSellingListBySupplierId(this.supplier.id)
            return true
        },
        changeCustomerSelect(val){
            console.log("=============  changeCustomerSelect  ========================")
            console.log(val)
            console.log("**************************************************")
            // this.loadSkuListBySupplierId(this.supplier.id)
            this.loadSellingListBySupplierId(this.supplier.id)
            return true
        },
        quantityChange(val){
            console.log("*************** quantityChange ********************")
            console.log("val ="+val)
            JSON.stringify(val)
            this.curNewProcurementRow.subTotalAmount = this.curNewProcurementRow.currentSellingPrice * val
        },
        formatStorage(row) {
            let temp = this.storageData.find(item => {
                return item.id == row.storage
            })

            return temp && temp.name
        },
        formatColortype(row) {
            let temp = this.goodstypeData.find(item => {
                return item.id == row.goodstype
            })
            return temp && temp.name
        },
        procurementChange(val){
            console.log("=========== procurementChange ==============")
            JSON.stringify(val)
        },
        changeSkuOption(val){
            console.log("=========== changeSkuOption ==============")
            JSON.stringify(val)
        },
        changeFn(row,index){
            console.log("=========== changeFn ==============")
            console.log(row)
            console.log("subjectNo = "+JSON.stringify(row.subjectItem))
            JSON.stringify(row.subjectItem)
            console.log("index = "+index)
            JSON.stringify(row)
            let temp = this.skuListData.find(item => {
                return item.skuId == row.subjectItem
            })
            this.curNewProcurementData[index].skuId = temp.skuId
            this.curNewProcurementData[index].currentSellingPrice = temp.currentSellingPrice
            this.curNewProcurementData[index].currentPurchasePrice = temp.currentPurchasePrice
            this.curNewProcurementData[index].name = temp.name
            // this.curNewProcurementData[index].subTotalAmount = row.subjectItem.skuPrice * row.subjectItem.inputQuantity
            // this.$set(this.curNewProcurementData);
            // this.$set(this.curNewProcurementData, index, this.curNewProcurementData[index]);
            this.loadSkuStorageListDataBySkuid(temp.skuId)
        },
        changeSkuStorage(row,index){
            console.log("=========== changeSkuStorage ==============")
            console.log(JSON.stringify(row))
            console.log("subjectNo = "+JSON.stringify(row.currentSkuStorageItem))
            JSON.stringify(row.currentSkuStorageItem)
            console.log("index = "+index)
            JSON.stringify(row)
            let temp = this.skuStorageListData.find(item => {
                return item.storageId == row.currentSkuStorageItem
            })
            console.log("+++++++++++++++++++")
            console.log(JSON.stringify(temp))
            row.availableQuantity = temp.quantity
        },
        onQuantityInputFocus(row,index){
            console.log("=========== onQuantityInputFocus ==============")
            console.log(row)
            console.log(index)
            this.curNewProcurementRow = row
        },
        addNewRowForPurchase(){
            this.curNewProcurementData.push({
                skuId: '',
                name: '',
                currentPurchasePrice: 0,
                currentSellingPrice: 0,
                quantity: 0,
                subTotalAmount: 0,
            })
        },
        postNewSalesOrder(){
            console.log("********* postNewSalesOrder  ******")
            // console.log(this.supplier)
            let detailsListData = []
            let detailsListDataNum = 0
            this.curNewProcurementData.forEach((v) => {
                console.log("====== vvvvvv")
                console.log(v)
                if(v.inputQuantity != 0 && v.skuId != '' && v.name != ''){
                    detailsListData.push({
                        "skuId" : v.skuId,
                        "quantity" : v.inputQuantity,
                        "storageId": v.currentSkuStorageItem,
                        "currentPurchasePrice": parseInt(v.currentPurchasePrice * 100),
                        "currentSellingPrice": parseInt(v.currentSellingPrice * 100),
                    })
                    detailsListDataNum = detailsListDataNum + 1
                }
            });
            console.log(JSON.stringify(detailsListData))
            this.$axios.post(this.$httpUrl + '/salesSummary/newSalesOrder', {
                "salesSummary":{
                    "customerId": this.customer.id,
                    "skuQuantity": detailsListDataNum
                },
                "detailsList": detailsListData
            }).then(res => res.data).then(res => {
                console.log(res)
                if (res.code == 200) {
                    this.$message({
                        message: '操作成功！',
                        type: 'success'
                    });
                    this.centerDialogVisible = false
                    this.newProcurementDialogVisible = false
                    this.loadPost()
                    // this.resetForm()
                } else {
                    this.$message({
                        message: '操作失败！',
                        type: 'error'
                    });
                }

            })
        },
        formatPrice(row) {
            return (row.price * 1.0 / 100).toFixed(2)
        },
        resetForm() {
            this.$refs.form.resetFields();
        },
        resetInForm() {
            this.$refs.form1.resetFields();
        },
        del(id) {
            console.log(id)

            this.$axios.get(this.$httpUrl + '/salesSummary/del?id=' + id).then(res => res.data).then(res => {
                console.log(res)
                if (res.code == 200) {

                    this.$message({
                        message: '操作成功！',
                        type: 'success'
                    });
                    this.loadPost()
                } else {
                    this.$message({
                        message: '操作失败！',
                        type: 'error'
                    });
                }

            })
        },
        showProcurementDetails(row) {
            console.log("点击showProcurementDetails")
            this.procurementDialogVisible = true
            this.loadProcurementIdNo = row.idNo
            this.loadProcurementDetailsByidNo(this.loadProcurementIdNo);
            // this.$nextTick(() => {
                //赋值到表单
                // this.form.id = row.id
                //this.form.partNo = row.partNo
                // this.form.name = row.name
                // this.form.imagesPath = row.imagesPath
                // this.form.color = row.color
                // this.form.qty = row.qty
                // this.form.unitName = row.unitName
                // this.form.price = row.price
                // this.form.valid = row.valid
                // this.form.category = row.category
            // })
        },
        mod(row) {
            this.centerDialogVisible = true
            this.$nextTick(() => {
                //赋值到表单
                this.form.id = row.id
                this.form.partNo = row.partNo
                this.form.name = row.name
                this.form.imagesPath = row.imagesPath
                this.form.color = row.color
                this.form.qty = row.qty
                this.form.unitName = row.unitName
                this.form.price = row.price
                this.form.valid = row.valid
                this.form.category = row.category
            })
        },
        add() {
            this.newProcurementDialogVisible = true
            //
            this.curNewProcurementData.splice(0,this.curNewProcurementData.length);
            this.curNewProcurementData.push({
                skuId: '',
                name: '',
                unitPrice: 0,
                quantity: 0,
                subTotalAmount: 0,
            })
            this.$nextTick(() => {
                // this.resetForm()
                // this.form.id = ''
            })

        },
        inGoods() {
            if (!this.currentRow.id) {
                alert('请选择记录');
                return;
            }
            this.orderImportVisible = true
            this.$nextTick(() => {
                this.resetInForm()
            })

            this.form1.goodsname = this.currentRow.name
            this.form1.goods = this.currentRow.id
            this.form1.adminId = this.user.id
            this.form1.action = '1'
        },
        outGoods() {
            if (!this.currentRow.id) {
                alert('请选择记录');
                return;
            }
            this.orderImportVisible = true
            this.$nextTick(() => {
                this.resetInForm()
            })

            this.form1.goodsname = this.currentRow.name
            this.form1.goods = this.currentRow.id
            this.form1.adminId = this.user.id
            this.form1.action = '2'

        },
        selectUser() {
            this.innerVisible = true
        },
        doSave() {
            console.log("price = " + parseInt(this.form.price * 100))
            let formTemp = { ...this.form }
            formTemp.price = parseInt(this.form.price * 100)
            this.$axios.post(this.$httpUrl + '/salesSummary/save', formTemp).then(res => res.data).then(res => {
                console.log(res)
                if (res.code == 200) {

                    this.$message({
                        message: '操作成功！',
                        type: 'success'
                    });
                    this.centerDialogVisible = false
                    this.loadPost()
                    this.resetForm()
                } else {
                    this.$message({
                        message: '操作失败！',
                        type: 'error'
                    });
                }

            })
        },
        doMod() {
            // let formTemp = {...this.form}
            // formTemp.price = parseInt(this.form.price*100)
            this.$axios.post(this.$httpUrl + '/salesSummary/update', { ...this.form, 'price': parseInt(this.form.price * 100) }).then(res => res.data).then(res => {
                console.log(res)
                if (res.code == 200) {

                    this.$message({
                        message: '操作成功！',
                        type: 'success'
                    });
                    this.centerDialogVisible = false
                    this.loadPost()
                    this.resetForm()
                } else {
                    this.$message({
                        message: '操作失败！',
                        type: 'error'
                    });
                }

            })
        },
        save() {
            this.$refs.form.validate((valid) => {
                if (valid) {
                    if (this.form.id) {
                        this.doMod();
                    } else {
                        this.doSave();
                    }
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });

        },
        doInGoods() {
            this.orderImportVisible = true
        },
        handleSizeChange(val) {
            console.log(`每页 ${val} 条`);
            this.pageNum = 1
            this.pageSize = val
            this.loadPost()
        },
        handleCurrentChange(val) {
            console.log(`当前页: ${val}`);
            this.pageNum = val
            this.loadPost()
        },
        handleDetailsSizeChange(val) {
            console.log(`每页 ${val} 条`);
            this.pageDetailsNum = 1
            this.pageDetailsSize = val
            this.loadProcurementDetailsByidNo(this.loadProcurementIdNo)
            
        },
        handleDetailsCurrentChange(val) {
            console.log(`当前页: ${val}`);
            this.pageDetailsNum = val
            this.loadProcurementDetailsByidNo(this.loadProcurementIdNo)
        },
        resetParam() {
            this.name = ''
            this.storage = ''
            this.goodstype = ''
        },
        loadPost() {
            this.$axios.post(this.$httpUrl + '/salesSummary/listPage', {
                pageSize: this.pageSize,
                pageNum: this.pageNum,
                param: {
                    name: this.name,
                }
            }).then(res => res.data).then(res => {
                console.log(res)
                if (res.code == 200) {
                    this.tableData = res.data.map(item => {//jacky.wei
                        return {
                            ...item,
                            totalPaymentAmount: (item.totalPaymentAmount * 1.0 / 100).toFixed(2),
                            payableAccounts: (item.payableAccounts * 1.0 / 100).toFixed(2),
                            paidAccounts: (item.paidAccounts * 1.0 / 100).toFixed(2),
                            receivablesAmount: (item.receivablesAmount * 1.0 / 100).toFixed(2),
                            receivedAmount: (item.receivedAmount * 1.0 / 100).toFixed(2),
                            totalSalesProfit: (item.totalSalesProfit * 1.0 / 100).toFixed(2),
                        }; // 在每个对象中添加一个新的属性age
                    })
                    this.total = res.total
                    console.log(JSON.stringify(this.tableData))
                } else {
                    alert('获取数据失败')
                }

            })
        },
        loadProcurementDetailsByidNo(idNo) {
            this.$axios.post(this.$httpUrl + '/salesDetails/listPage', {
                pageSize: this.pageDetailsSize,
                pageNum: this.pageDetailsNum,
                param: {
                    idNo: idNo,
                }
            }).then(res => res.data).then(res => {
                console.log(res)
                if (res.code == 200) {
                    this.procurementData = res.data.map(item => {//jacky.wei
                        return {
                            ...item,
                            unitPrice: (item.unitPrice * 1.0 / 100).toFixed(2),
                            subTotalAmount: (item.subTotalAmount * 1.0 / 100).toFixed(2),
                            subTotalProfit: (item.subTotalProfit * 1.0 / 100).toFixed(2),
                            currentSellingPrice: (item.currentSellingPrice * 1.0 / 100).toFixed(2),
                        }; // 在每个对象中添加一个新的属性age
                    })
                    this.detailsTotal = res.total
                } else {
                    alert('获取数据失败')
                }
            })
        },
        loadStorage() {
            this.$axios.get(this.$httpUrl + '/storage/list').then(res => res.data).then(res => {
                console.log(res)
                if (res.code == 200) {
                    this.storageData = res.data
                } else {
                    alert('获取数据失败')
                }

            })
        },
        loadSupplierList() {
            this.$axios.get(this.$httpUrl + '/supplier/list').then(res => res.data).then(res => {
                console.log(res)
                if (res.code == 200) {
                    this.supplierData = res.data
                } else {
                    alert('获取数据失败')
                }

            })
        },
        loadCustomerList() {
            this.$axios.get(this.$httpUrl + '/customer/list').then(res => res.data).then(res => {
                console.log(res)
                if (res.code == 200) {
                    this.customerData = res.data
                } else {
                    alert('获取数据失败')
                }

            })
        },
        loadGoodstype() {
            this.$axios.get(this.$httpUrl + '/goodstype/list').then(res => res.data).then(res => {
                console.log(res)
                if (res.code == 200) {
                    this.goodstypeData = res.data
                } else {
                    alert('获取数据失败')
                }

            })
        },
        loadSkuListBySupplierId(id) {
            this.$axios.get(this.$httpUrl + '/supplierSku/list?id='+id).then(res => res.data).then(res => {
                console.log(res)
                if (res.code == 200) {
                    this.skuListData = res.data.map(item => {
                        return {
                            ...item,
                            skuPrice:(item.skuPrice * 1.0 / 100).toFixed(2)
                        }
                    })
                    console.log("-----------------------")
                    console.log(this.skuListData)
                } else {
                    alert('获取Sku数据失败')
                }

            })
        },
        loadSkuStorageListDataBySkuid(id){
            this.$axios.get(this.$httpUrl + '/skuInventory/QuerySkuidInStock?skuid='+id).then(res => res.data).then(res => {
                console.log(res)
                if (res.code == 200) {
                    this.skuStorageListData = res.data
                    console.log("-----------------------")
                    console.log(this.skuStorageListData)
                } else {
                    alert('获取Sku数据失败')
                }

            })
        },
        loadSellingListBySupplierId(id) {
            this.$axios.get(this.$httpUrl + '/sellingPrice/list?id='+id).then(res => res.data).then(res => {
                console.log(res)
                if (res.code == 200) {
                    this.skuListData = res.data.map(item => {
                        return {
                            ...item,
                            currentPurchasePrice:(item.currentPurchasePrice * 1.0 / 100).toFixed(2),
                            currentSellingPrice:(item.currentSellingPrice * 1.0 / 100).toFixed(2),
                        }
                    })
                    console.log("-----------------------")
                    console.log(this.skuListData)
                } else {
                    alert('获取Sku数据失败')
                }

            })
        },
      //文件选择时
      onChange(file) {
        // this.$set(this.outputs,[])
        // this.outputs = []
        // this.outputs.splice(0,this.outputs.length);
        this.fileData = file; // 保存当前选择文件
        this.readExcel(); // 调用读取数据的方法
      },
  
      //读取文件数据
      readExcel() {
        let that = this;
        const files = that.fileData;
        if (!files) {
          //如果没有文件
          return false;
        } else if (!/\.(xls|xlsx)$/.test(files.name.toLowerCase())) {
          this.$message.error("上传格式不正确，请上传xls或者xlsx格式");
          return false;
        }
        // let purCreateTime = files.name.match(/.*\\-[0-9]{6}/)
        let purCreateTime = files.name.match(/.*-([0-9]{8})/);
        console.log("purCreateTime = "+purCreateTime)
        let putToServerCreateTime = purCreateTime[1].slice(0,4)+"-"+purCreateTime[1].slice(4,6)+"-"+purCreateTime[1].slice(6,8)+" 00:00:00"
        console.log("putToServerCreateTime = "+putToServerCreateTime)
        const fileReader = new FileReader();
        fileReader.onload = ev => {
          try {
            const data = ev.target.result;
            const workbook = XLSX.read(data, {
              type: "binary"
            });
            //导入的文件名称
            console.log(workbook.SheetNames);
            const wsname = workbook.SheetNames[0]; //取第一张表
            const ws = XLSX.utils.sheet_to_json(workbook.Sheets[wsname]); //生成json表格内容
            that.outputs = []; //清空接收数据
            let totalNum = 0
            let totalPrice = 0.0
            for (var i = 0; i < ws.length; i++) {
                if (ws[i]["数量"] > 0){
                    var sheetData = {
                        // 键名为绑定 el 表格的关键字，值则是 ws[i][对应表头名]
                        //此处的字段名对应 上面表格数据的字段名
                        partNo : ws[i]["U9料号"],
                        skuUnit: ws[i]["单位"],
                        qty: parseInt(ws[i]["装箱数"]),
                        quantity : parseInt(ws[i]["数量"]),
                        name: ws[i]["产品名称"],
                        unitPrice: parseFloat((ws[i]["单价/元"]).toFixed(2)),
                        subTotal: parseFloat((ws[i]["小 计"]).toFixed(2)),
                        createTime: putToServerCreateTime,
                    };
                    totalNum = totalNum + ws[i]["数量"]
                    totalPrice = totalPrice + ws[i]["小 计"]
                    that.outputs.push(sheetData);
                }
            }
            let summaryInfo = {
                quantity: totalNum,
                name: "数量总计 :",
                unitPrice: "价格总计 :",
                subTotal: totalPrice.toFixed(2),
            }
            that.outputs.push(summaryInfo);
          } catch (e) {
            console.log(e);
            return false;
          }
        };
        // 如果为原生 input 则应是 files[0]
        fileReader.readAsBinaryString(files.raw)
      },
      importOrder(){
        let detailsListData = []
        let detailsListDataNum = 0
        let importOrderCreateTime
        for (var i = 0; i < this.outputs.length - 1; i++) {
            let tempItem = this.outputs[i]
            detailsListData.push({
                "quantity" : tempItem.quantity,
                "unitPrice" : tempItem.unitPrice * 100,
                "subTotalAmount" : tempItem.subTotal * 100,
                "partNo" : tempItem.partNo,
                "unitName": tempItem.skuUnit,
                "name": tempItem.name,
                "qty": tempItem.qty,
                "createTime": tempItem.createTime
            })
            detailsListDataNum = detailsListDataNum + 1
            importOrderCreateTime = tempItem.createTime
        }
        console.log(JSON.stringify(detailsListData))
        this.$axios.post(this.$httpUrl + '/salesSummary/newProcurementByExcel', {
                "procurementSummary":{
                    "supplierId": 1,
                    "skuQuantity": detailsListDataNum,
                    "createTime": importOrderCreateTime,
                },
                "detailsList": detailsListData
            }).then(res => res.data).then(res => {
                console.log(res)
                if (res.code == 200) {
                    this.$message({
                        message: '操作成功！',
                        type: 'success'
                    });
                    this.outputs.splice(0,this.outputs.length)
                    this.loadPost()
                    this.orderImportVisible = false
                    // this.resetForm()
                } else {
                    this.$message({
                        message: '操作失败！',
                        type: 'error'
                    });
                }
            })

      },
      importOrderCancel(){
        // this.outputs.splice(0,this.outputs.length)
        this.orderImportVisible = false
      }
    },
    beforeMount() {
        this.loadStorage()
        this.loadGoodstype()
        this.loadPost()
        this.loadSupplierList()
        this.loadCustomerList()
    }
}
</script>

<style scoped></style>